import { defineStore } from "pinia";
import { ref } from "vue";

export const usePromtUser = defineStore("prompt-user", () => {
    let resolvePromise;
    const show = ref(false);
    const saveButtonText = ref("Yes");
    const cancelButtonText = ref("No");
    const thirdOptionButtonText = ref("Continue");
    const promptMessage = ref("Message");
    const promptTitle = ref("Title");
    const hasThirdOption = ref(false);
    const callBackFunc = ref(null);

    const openPrompt = (isGlobal = true) => {
        if (isGlobal) {
            show.value = true;
        }
        return new Promise((resolve) => {
            resolvePromise = resolve;
        });
    };
    const closePromptModal = () => {
        show.value = false;
    };

    const getUserInput = (userInput) => {
        if (!resolvePromise) return;
        resolvePromise(userInput);
    };

    const optionalFunc = () => {
        if (!callBackFunc.value)
            throw new Error(
                'The function "callBackFunc" in usePromtUser.js is not set.',
            );
        return callBackFunc.value();
    };
    return {
        show,
        openPrompt,
        promptTitle,
        getUserInput,
        optionalFunc,
        callBackFunc,
        promptMessage,
        saveButtonText,
        hasThirdOption,
        cancelButtonText,
        closePromptModal,
        thirdOptionButtonText,
    };
});
